import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ArrowRight, LogIn, BarChart2, Brain, Users } from 'lucide-react';
import YouTube from 'react-youtube';
import api from '../utils/api';

const HomeContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('/images/hero-image.jpg');
  background-size: cover;
  background-position: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 0 20px;
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: ${props => props.theme.primary};
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: ${props => props.theme.text};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Button = styled(motion(Link))`
  padding: 12px 24px;
  background-color: ${props => props.primary ? props.theme.primary : 'transparent'};
  color: ${props => props.primary ? props.theme.text : props.theme.primary};
  border: 2px solid ${props => props.theme.primary};
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.primary ? props.theme.accent1 : props.theme.background};
  }
`;

const IntroVideoSection = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.theme.cardBackground};
`;

const VideoContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const FeaturesSection = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.theme.cardBackground};
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureCard = styled(motion.div)`
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 20px;
  color: ${props => props.theme.primary};
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: ${props => props.theme.primary};
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.textSecondary};
  margin: 0 0 15px 0;
`;

const Footer = styled.footer`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.textSecondary};
  text-align: center;
  padding: 20px;
  font-size: 0.9rem;
`;

function Home() {
  const [introVideoId, setIntroVideoId] = React.useState(null);

  React.useEffect(() => {
    const fetchIntroVideo = async () => {
      try {
        const response = await api.get('/videos/intro');
        setIntroVideoId(response.data.youtubeId);
      } catch (error) {
        console.error('Error fetching intro video:', error);
      }
    };

    fetchIntroVideo();
  }, []);

  return (
    <HomeContainer>
      <HeroSection>
        <HeroContent>
          <Title
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            LoL Performance Hub
          </Title>
          <Subtitle
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Elevate your League of Legends gameplay with AI-powered insights and personalized recommendations
          </Subtitle>
          <ButtonContainer>
            <Button
              to="/register"
              primary
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Get Started <ArrowRight size={20} />
            </Button>
            <Button
              to="/login"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Login <LogIn size={20} />
            </Button>
          </ButtonContainer>
        </HeroContent>
      </HeroSection>

      {introVideoId && (
        <IntroVideoSection>
          <VideoContainer>
            <YouTube
              videoId={introVideoId}
              opts={{
                width: '100%',
                height: '450',
                playerVars: {
                  autoplay: 0,
                },
              }}
            />
          </VideoContainer>
        </IntroVideoSection>
      )}

      <FeaturesSection>
        <FeatureGrid>
          <FeatureCard
            whileHover={{ y: -10 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <FeatureIcon><BarChart2 size={48} /></FeatureIcon>
            <FeatureTitle>Advanced Analytics</FeatureTitle>
            <FeatureDescription>
              Dive deep into your performance stats with intuitive visualizations and trend analysis
            </FeatureDescription>
          </FeatureCard>
          <FeatureCard
            whileHover={{ y: -10 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <FeatureIcon><Brain size={48} /></FeatureIcon>
            <FeatureTitle>AI-Powered Insights</FeatureTitle>
            <FeatureDescription>
              Receive personalized recommendations and strategies based on your unique playstyle
            </FeatureDescription>
          </FeatureCard>
          <FeatureCard
            whileHover={{ y: -10 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <FeatureIcon><Users size={48} /></FeatureIcon>
            <FeatureTitle>Community Insights</FeatureTitle>
            <FeatureDescription>
              Learn from top players and share strategies with a community of dedicated gamers
            </FeatureDescription>
          </FeatureCard>
        </FeatureGrid>
      </FeaturesSection>

      <Footer>
        © {new Date().getFullYear()} LoL Performance Hub. All rights reserved.
      </Footer>
    </HomeContainer>
  );
}

export default Home;
