// AdminComponents/VideoCard.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Edit, Trash2 } from 'lucide-react';

const Card = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const Thumbnail = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  background-color: ${props => props.theme.secondary};
  overflow: hidden;
`;

const ThumbnailImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoInfo = styled.div`
  padding: 20px;
`;

const VideoTitle = styled.h3`
  margin: 0 0 10px 0;
  color: ${props => props.theme.text};
  font-size: 1.2rem;
`;

const VideoDescription = styled.p`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
  margin-bottom: 15px;
`;

const VideoMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.textSecondary};
  font-size: 0.8rem;
`;

const VideoActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 20px 20px;
`;

const IconButton = styled(motion.button)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  color: ${props => props.theme.text};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 10px;
`;

const Tag = styled.span`
  background-color: ${props => props.theme.accent2};
  color: ${props => props.theme.text};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
`;

function VideoCard({ video, onEdit, onDelete }) {
  return (
    <Card
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
    >
      <Thumbnail>
        <ThumbnailImage src={`https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`} alt={video.title} />
      </Thumbnail>
      <VideoInfo>
        <VideoTitle>{video.title}</VideoTitle>
        <VideoDescription>{video.description.substring(0, 100)}...</VideoDescription>
        <VideoMeta>
          <span>Category: {video.category}</span>
          <span>Skill Level: {video.skillLevel}</span>
        </VideoMeta>
        <TagList>
          {video.tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </TagList>
      </VideoInfo>
      <VideoActions>
        <IconButton
          onClick={() => onEdit(video)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Edit size={18} />
        </IconButton>
        <IconButton
          onClick={() => onDelete(video._id)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Trash2 size={18} />
        </IconButton>
      </VideoActions>
    </Card>
  );
}

export default VideoCard;
