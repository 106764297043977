import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from './ThemeContext';
import GlobalStyles from './GlobalStyles';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ProfileManagement from './components/ProfileManagement';
import UserSettings from './components/UserSettings';
import AdminPanel from './components/AdminPanel';
import Header from './components/Header';
import ErrorBoundary from './components/ErrorBoundary';
import AccessibilityProvider from './components/AccessibilityProvider';
import CoachingVideos from './components/CoachingVideos';
import VideoPlayer from './components/VideoPlayer';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" replace />;
};

const AdminRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  return token && isAdmin ? children : <Navigate to="/dashboard" replace />;
};

function App() {
  return (
    <ThemeProvider>
      <GlobalStyles />
      <Helmet>
        <title>LoL Performance Hub</title>
        <meta name="description" content="Improve your League of Legends gameplay with personalized coaching and analysis" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <AccessibilityProvider>
        <ErrorBoundary>
          <Router>
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile-management"
                  element={
                    <PrivateRoute>
                      <ProfileManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <UserSettings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <AdminRoute>
                      <AdminPanel />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/coaching-videos"
                  element={
                    <PrivateRoute>
                      <CoachingVideos />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/videos/:id"
                  element={
                    <PrivateRoute>
                      <VideoPlayer />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </main>
          </Router>
        </ErrorBoundary>
      </AccessibilityProvider>
    </ThemeProvider>
  );
}

export default App;
