import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const ModalTitle = styled.h2`
  color: ${props => props.theme.primary};
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.secondary};
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: ${props => props.primary ? props.theme.primary : props.theme.cardBackground};
  color: ${props => props.primary ? props.theme.text : props.theme.textSecondary};
`;

const FeedbackModal = ({ isOpen, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState('');

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(feedback);
    setFeedback('');
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Provide Feedback</ModalTitle>
        <TextArea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="How can we improve this recommendation?"
        />
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={handleSubmit}>Submit</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default FeedbackModal;
