import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: ${props => props.theme.background};
    color: ${props => props.theme.text};
    transition: all 0.3s ease;
  }

  * {
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
    padding-top: 60px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.primary};
    ${props => props.theme.themeName === 'vanGogh' && `
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    `}
  }

  button {
    font-family: inherit;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: ${props => props.theme.boxShadow};
    }

    ${props => props.theme.themeName === 'vanGogh' && `
      background-image: ${props.theme.brushStroke};
      background-size: 200% 200%;
      animation: brushStroke 5s ease infinite;
    `}
  }

  input, textarea, select {
    font-family: inherit;
    background-color: ${props => props.theme.cardBackground};
    color: ${props => props.theme.text};
    border: 1px solid ${props => props.theme.secondary};
    border-radius: ${props => props.theme.borderRadius};
    padding: 8px 12px;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: ${props => props.theme.primary};
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    html {
      font-size: 12px;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.primary};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.accent2};
  }

  /* Accessibility Styles */
  :focus {
    outline: 2px solid ${props => props.theme.accent1};
    outline-offset: 2px;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  ${props => props.theme.themeName === 'vanGogh' && `
    @keyframes brushStroke {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    .card, .button, .input {
      background-image: ${props.theme.brushStroke};
      background-size: 200% 200%;
      animation: brushStroke 10s ease infinite;
    }
  `}
`;

export default GlobalStyles;
