import React from 'react';
import styled from 'styled-components';
import { LogOut, Settings, Shield, Video } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.cardBackground};
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const Logo = styled(Link)`
  color: ${props => props.theme.primary};
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
`;

const NavContainer = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.text};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  transition: color 0.3s;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const LogoutButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const Header = () => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem('isAdmin') === 'true';

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin');
    navigate('/login');
  };

  return (
    <HeaderContainer>
      <Logo to="/dashboard">LoL Performance Hub</Logo>
      <NavContainer>
        <NavLink to="/dashboard">Dashboard</NavLink>
        <NavLink to="/coaching-videos">
          <Video size={18} />
          Coaching Videos
        </NavLink>
        <NavLink to="/settings">
          <Settings size={18} />
          Settings
        </NavLink>
        {isAdmin && (
          <NavLink to="/admin">
            <Shield size={18} />
            Admin
          </NavLink>
        )}
        <ThemeSwitcher />
        <LogoutButton onClick={handleLogout}>
          <LogOut size={18} />
          Logout
        </LogoutButton>
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header;
