import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { X, PlayCircle, ChevronRight } from 'lucide-react';

const OverlayContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const OverlayContent = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  padding: 40px;
  border-radius: 16px;
  max-width: 600px;
  width: 90%;
  text-align: center;
`;

const Title = styled.h2`
  color: ${props => props.theme.primary};
  margin-bottom: 20px;
  font-size: 2rem;
`;

const Description = styled.p`
  color: ${props => props.theme.text};
  margin-bottom: 30px;
  font-size: 1.1rem;
  line-height: 1.6;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Button = styled(motion.button)`
  padding: 12px 24px;
  background-color: ${props => props.primary ? props.theme.primary : 'transparent'};
  color: ${props => props.primary ? props.theme.text : props.theme.primary};
  border: 2px solid ${props => props.theme.primary};
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.primary ? props.theme.accent1 : props.theme.background};
  }
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  font-size: 1.5rem;
`;

const WelcomeOverlay = ({ isOpen, onClose, onWatchIntro }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <OverlayContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <OverlayContent
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: 'spring', damping: 15 }}
          >
            <Title>Welcome to LoL Performance Hub!</Title>
            <Description>
              Get ready to elevate your League of Legends gameplay with personalized insights,
              recommendations, and powerful analytics. Let's get you started on your journey to improvement!
            </Description>
            <ButtonContainer>
              <Button primary onClick={onWatchIntro} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <PlayCircle size={20} /> Watch Intro
              </Button>
              <Button onClick={onClose} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                Explore Dashboard <ChevronRight size={20} />
              </Button>
            </ButtonContainer>
            <CloseButton onClick={onClose} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <X />
            </CloseButton>
          </OverlayContent>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
};

export default WelcomeOverlay;
