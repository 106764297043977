// frontend/src/components/PerformanceTrends.js
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { TrendingUp } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { animated, useSpring } from 'react-spring';
import { useAnimatedMount, useResponsive } from '../hooks/useAnimations';
import LoadingSpinner from './LoadingSpinner';
import ErrorBoundary from './ErrorBoundary';
import { useTheme } from '../ThemeContext';

const TrendsContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing.medium};
  box-shadow: ${props => props.theme.boxShadow};
`;

const Title = styled.h2`
  color: ${props => props.theme.primary};
  font-size: 1.2rem;
  margin-bottom: ${props => props.theme.spacing.medium};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
`;

const ChartContainer = styled.div`
  height: ${props => props.$isMobile ? '300px' : '400px'};
  margin-bottom: ${props => props.theme.spacing.medium};
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: ${props => props.theme.spacing.medium};
`;

const StatItem = styled(animated.div)`
  text-align: center;
  cursor: pointer;
`;

const StatLabel = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
`;

const StatValue = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${props => props.$active ? props.theme.primary : props.theme.textSecondary};
`;

const CustomTooltip = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.secondary};
  padding: ${props => props.theme.spacing.small};
  border-radius: ${props => props.theme.borderRadius};
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 1.2rem;
  color: ${props => props.theme.textSecondary};
`;

const PerformanceTrends = ({ playerData, currentGame, isProfilePrivate, hasNoGameHistory, loading }) => {
  const [activeMetrics, setActiveMetrics] = useState(['kda', 'csPerMin', 'visionScore']);
  const { isMobile } = useResponsive();
  const { currentTheme } = useTheme();

  const metrics = useMemo(() => [
    { key: 'kda', name: 'KDA', color: currentTheme.accent1 },
    { key: 'csPerMin', name: 'CS/min', color: currentTheme.accent2 },
    { key: 'visionScore', name: 'Vision Score', color: currentTheme.accent3 },
  ], [currentTheme]);

  const processedData = useMemo(() => {
    if (!playerData || !Array.isArray(playerData.matches) || playerData.matches.length === 0) {
      return [];
    }
    return playerData.matches.map((match, index) => ({
      game: index + 1,
      ...metrics.reduce((acc, metric) => {
        acc[metric.key] = parseFloat((match[metric.key] || 0).toFixed(2));
        return acc;
      }, {})
    }));
  }, [playerData, metrics]);

  const averageStats = useMemo(() => {
    if (processedData.length === 0) return {};
    return metrics.reduce((acc, metric) => {
      acc[metric.key] = parseFloat((processedData.reduce((sum, match) => sum + (match[metric.key] || 0), 0) / processedData.length).toFixed(2));
      return acc;
    }, {});
  }, [processedData, metrics]);

  const toggleMetric = (key) => {
    setActiveMetrics(prev =>
      prev.includes(key)
        ? prev.filter(k => k !== key)
        : [...prev, key]
    );
  };

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltip>
          <p>{`Game ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ))}
        </CustomTooltip>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <TrendsContainer>
        <Title><TrendingUp /> Performance Trends</Title>
        <LoadingSpinner />
      </TrendsContainer>
    );
  }

  if (isProfilePrivate) {
    return (
      <TrendsContainer>
        <Title><TrendingUp /> Performance Trends</Title>
        <NoDataMessage>This profile is private. Make it public to view performance trends.</NoDataMessage>
      </TrendsContainer>
    );
  }

  if (hasNoGameHistory) {
    return (
      <TrendsContainer>
        <Title><TrendingUp /> Performance Trends</Title>
        <NoDataMessage>No game history found for this profile.</NoDataMessage>
      </TrendsContainer>
    );
  }

  if (!playerData || !Array.isArray(playerData.matches) || playerData.matches.length === 0) {
    return (
      <TrendsContainer>
        <Title><TrendingUp /> Performance Trends</Title>
        <NoDataMessage>No performance data available.</NoDataMessage>
      </TrendsContainer>
    );
  }

  return (
    <ErrorBoundary fallback={<NoDataMessage>An error occurred while rendering the performance trends.</NoDataMessage>}>
      <TrendsContainer>
        <Title><TrendingUp /> Performance Trends</Title>
        <ChartContainer $isMobile={isMobile}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={processedData}>
              <CartesianGrid strokeDasharray="3 3" stroke={currentTheme.secondary} />
              <XAxis dataKey="game" stroke={currentTheme.text} />
              <YAxis stroke={currentTheme.text} />
              <Tooltip content={<CustomizedTooltip />} />
              <Legend />
              {metrics.map((metric) => (
                activeMetrics.includes(metric.key) && (
                  <Line
                    key={metric.key}
                    type="monotone"
                    dataKey={metric.key}
                    stroke={metric.color}
                    name={metric.name}
                    dot={{ r: 4, strokeWidth: 2, fill: currentTheme.background }}
                    activeDot={{ r: 6, strokeWidth: 0, fill: metric.color }}
                  />
                )
              ))}
            </LineChart>
          </ResponsiveContainer>
        </ChartContainer>
        <StatsContainer>
          {metrics.map((metric) => (
            <StatItem
              key={metric.key}
              onClick={() => toggleMetric(metric.key)}
            >
              <StatLabel>{metric.name}</StatLabel>
              <StatValue $active={activeMetrics.includes(metric.key)}>
                {averageStats[metric.key] || 'N/A'}
              </StatValue>
            </StatItem>
          ))}
        </StatsContainer>
      </TrendsContainer>
    </ErrorBoundary>
  );
};

export default PerformanceTrends;
