import React from 'react';
import styled from 'styled-components';
import { AlertCircle } from 'lucide-react';

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.accent3};
  margin-bottom: 16px;
  border: 1px solid ${props => props.theme.accent3};
`;

const AlertIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${props => props.theme.accent3};
`;

const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AlertTitle = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;

const AlertDescription = styled.p`
  margin: 4px 0 0;
  font-size: 14px;
`;

const AlertAction = styled.button`
  margin-left: auto;
  padding: 8px 16px;
  background-color: ${props => props.theme.accent3};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.accent2};
  }
`;

const Alert = ({ title, description, icon, action }) => (
  <AlertContainer>
    <AlertIcon>{icon || <AlertCircle />}</AlertIcon>
    <AlertContent>
      <AlertTitle>{title}</AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
    </AlertContent>
    {action && (
      <AlertAction onClick={action.onClick}>
        {action.label}
      </AlertAction>
    )}
  </AlertContainer>
);

export default Alert;
