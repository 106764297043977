import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '../ThemeContext';
import { themes } from '../theme';
import { Moon, Sun } from 'lucide-react';

const SwitcherContainer = styled.div`
  position: relative;
`;

const ThemeButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
`;

const ThemeOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.theme.cardBackgroundHover};
  }
`;

const ColorSwatch = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => props.color};
`;

const ThemeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ThemeName = styled.span`
  font-weight: bold;
`;

const ThemeDescription = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.textSecondary};
`;

const themeDescriptions = {
  blueDark: "Classic (Dark)",
  feminineLight: "Feminine (Light)",
  feminineDark: "Feminine (Dark)",
  beautifulLight: "Vibrant (Light)",
  beautifulDark: "Vibrant (Dark)",
};

const ThemeSwitcher = () => {
  const { themeName, setTheme, toggleDarkMode, currentTheme } = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    setModalOpen(false);
  };

  return (
    <SwitcherContainer>
      <ThemeButton onClick={() => setModalOpen(true)}>
        {currentTheme.mode === 'dark' ? <Moon /> : <Sun />}
      </ThemeButton>
      {modalOpen && (
        <ModalOverlay onClick={() => setModalOpen(false)}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <h3>Select Theme</h3>
            {Object.entries(themes).map(([key, theme]) => (
              <ThemeOption key={key} onClick={() => handleThemeChange(key)}>
                <ColorSwatch color={theme.primary} />
                <ThemeInfo>
                  <ThemeName>{theme.name}</ThemeName>
                  <ThemeDescription>{themeDescriptions[key]}</ThemeDescription>
                </ThemeInfo>
              </ThemeOption>
            ))}
            <ThemeOption onClick={toggleDarkMode}>
              <ThemeInfo>
                <ThemeName>Toggle Dark Mode</ThemeName>
                <ThemeDescription>Switch between light and dark versions</ThemeDescription>
              </ThemeInfo>
            </ThemeOption>
          </Modal>
        </ModalOverlay>
      )}
    </SwitcherContainer>
  );
};

export default ThemeSwitcher;
