import { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';

export const useAnimatedMount = () => {
  const [show, setShow] = useState(false);
  const props = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? 'translateY(0px)' : 'translateY(50px)',
  });

  useEffect(() => {
    setShow(true);
  }, []);

  return props;
};

export const useResponsive = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile: width < 768,
    isTablet: width >= 768 && width < 1024,
    isDesktop: width >= 1024,
  };
};
