// frontend/src/components/AdminComponents/IntroVideoSection.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Play } from 'lucide-react';
import api from '../../utils/api';
import Toast from '../Toast';
import LoadingSpinner from '../LoadingSpinner';

const SectionContainer = styled.div`
  margin-top: 20px;
`;

const CurrentIntroVideo = styled.div`
  background-color: ${props => props.theme.cardBackground};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const VideoTitle = styled.h3`
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
`;

const VideoList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const VideoItem = styled.li`
  background-color: ${props => props.theme.cardBackground};
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SetIntroButton = styled.button`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.error};
  background-color: ${props => props.theme.cardBackground};
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const IntroVideoSection = ({ videos, fetchVideos }) => {
  const [introVideo, setIntroVideo] = useState(null);
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchIntroVideo();
  }, []);

  const fetchIntroVideo = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/videos/intro');
      setIntroVideo(response.data);
    } catch (error) {
      console.error('Error fetching intro video:', error);
      setError('Failed to load intro video. Please try again later.');
      setToast({ message: 'Error fetching intro video: ' + error.message, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSetIntroVideo = async (videoId) => {
    try {
      const response = await api.post(`/videos/intro/${videoId}`);
      setIntroVideo(response.data);
      setToast({ message: 'Intro video set successfully', type: 'success' });
      fetchVideos(); // Refresh the video list
    } catch (error) {
      console.error('Error setting intro video:', error);
      setToast({ message: 'Error setting intro video: ' + error.message, type: 'error' });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <SectionContainer>
      <h2>Intro Video Management</h2>
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : introVideo ? (
        <CurrentIntroVideo>
          <VideoTitle>Current Intro Video</VideoTitle>
          <p>{introVideo.title}</p>
        </CurrentIntroVideo>
      ) : (
        <p>No intro video set</p>
      )}
      <h3>Available Videos</h3>
      <VideoList>
        {videos.map(video => (
          <VideoItem key={video._id}>
            <span>{video.title}</span>
            <SetIntroButton onClick={() => handleSetIntroVideo(video._id)}>
              <Play size={16} /> Set as Intro
            </SetIntroButton>
          </VideoItem>
        ))}
      </VideoList>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </SectionContainer>
  );
};

export default IntroVideoSection;
