import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { X, CheckCircle } from 'lucide-react';
import api from '../utils/api';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  padding: 40px;
  border-radius: 16px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled(motion.button)`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  font-size: 1.5rem;
`;

const Title = styled.h2`
  color: ${props => props.theme.primary};
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: center;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-bottom: 30px;
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
`;

const FeatureItem = styled.li`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.1rem;
  color: ${props => props.theme.text};
`;

const IntroModal = ({ isOpen, onClose }) => {
  const [introVideo, setIntroVideo] = useState(null);

  useEffect(() => {
    const fetchIntroVideo = async () => {
      try {
        const response = await api.get('/videos/intro');
        setIntroVideo(response.data);
      } catch (error) {
        console.error('Error fetching intro video:', error);
      }
    };

    if (isOpen) {
      fetchIntroVideo();
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: 'spring', damping: 15 }}
          >
            <CloseButton onClick={onClose} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <X />
            </CloseButton>
            <Title>Welcome to LoL Performance Hub</Title>
            {introVideo && (
              <VideoWrapper>
                <Video
                  src={`https://www.youtube.com/embed/${introVideo.youtubeId}`}
                  title="Introduction Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </VideoWrapper>
            )}
            <FeatureList>
              <FeatureItem>
                <CheckCircle color={props => props.theme.primary} />
                Analyze your recent game performance
              </FeatureItem>
              <FeatureItem>
                <CheckCircle color={props => props.theme.primary} />
                Get personalized recommendations
              </FeatureItem>
              <FeatureItem>
                <CheckCircle color={props => props.theme.primary} />
                Access curated learning resources
              </FeatureItem>
              <FeatureItem>
                <CheckCircle color={props => props.theme.primary} />
                Track your progress over time
              </FeatureItem>
            </FeatureList>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default IntroModal;
