// frontend/src/components/VideoList.js

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const VideoListContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const VideoListTitle = styled.h2`
  color: ${props => props.theme.primary};
  margin-bottom: 20px;
`;

const VideoItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: ${props => props.theme.cardBackground};
  }
`;

const Thumbnail = styled.img`
  width: 120px;
  height: 67px;
  object-fit: cover;
  margin-right: 20px;
`;

const VideoInfo = styled.div`
  flex: 1;
`;

const VideoTitle = styled.h3`
  color: ${props => props.theme.text};
  margin: 0 0 5px 0;
`;

const VideoDescription = styled.p`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9em;
  margin: 0;
`;

function VideoList({ videos }) {
  return (
    <VideoListContainer>
      <VideoListTitle>Recommended Videos</VideoListTitle>
      {videos && videos.length > 0 ? (
        videos.map(video => (
          <VideoItem key={video._id} to={`/videos/${video._id}`}>
            <Thumbnail
              src={`https://img.youtube.com/vi/${video.youtubeId}/mqdefault.jpg`}
              alt={video.title}
            />
            <VideoInfo>
              <VideoTitle>{video.title}</VideoTitle>
              <VideoDescription>{video.description.substring(0, 100)}...</VideoDescription>
            </VideoInfo>
          </VideoItem>
        ))
      ) : (
        <p>No recommended videos available at the moment.</p>
      )}
    </VideoListContainer>
  );
}

export default VideoList;
