// frontend/src/components/AdminComponents/CommentModeration.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Check, X, AlertTriangle, RefreshCw } from 'lucide-react';
import api from '../../utils/api';
import LoadingSpinner from '../LoadingSpinner';
import Toast from '../Toast';

const CommentModerationContainer = styled.div`
  margin-top: 20px;
`;

const CommentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CommentItem = styled.li`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommentInfo = styled.div`
  flex-grow: 1;
`;

const CommentText = styled.p`
  margin: 0 0 10px 0;
`;

const CommentMeta = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.textSecondary};
`;

const ActionButton = styled.button`
  background-color: ${props => props.$approve ? props.theme.success : props.theme.error};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.error};
  text-align: center;
  margin: 20px 0;
`;

const RetryButton = styled.button`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

function CommentModeration() {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);

  const fetchPendingComments = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/comments/pending');
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching pending comments:', error);
      if (error.response && error.response.status === 401) {
        setError('You are not authorized to view pending comments. Please log in as an admin.');
      } else {
        setError('Failed to load pending comments. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingComments();
  }, []);

  const handleApprove = async (commentId) => {
    try {
      await api.patch(`/comments/${commentId}`, { approved: true });
      setToast({ message: 'Comment approved', type: 'success' });
      fetchPendingComments();
    } catch (error) {
      console.error('Error approving comment:', error);
      setToast({ message: 'Error approving comment', type: 'error' });
    }
  };

  const handleReject = async (commentId) => {
    try {
      await api.patch(`/comments/${commentId}`, { approved: false });
      setToast({ message: 'Comment rejected', type: 'success' });
      fetchPendingComments();
    } catch (error) {
      console.error('Error rejecting comment:', error);
      setToast({ message: 'Error rejecting comment', type: 'error' });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <ErrorContainer>
        <AlertTriangle size={48} />
        <ErrorMessage>{error}</ErrorMessage>
        <RetryButton onClick={fetchPendingComments}>
          <RefreshCw size={16} />
          Retry
        </RetryButton>
      </ErrorContainer>
    );
  }

  return (
    <CommentModerationContainer>
      <h2>Pending Comments</h2>
      {comments.length === 0 ? (
        <p>No pending comments at the moment.</p>
      ) : (
        <CommentList>
          {comments.map((comment) => (
            <CommentItem key={comment._id}>
              <CommentInfo>
                <CommentText>{comment.text}</CommentText>
                <CommentMeta>
                  By: {comment.user?.username || 'Unknown'} | Video: {comment.video?.title || 'Unknown'}
                </CommentMeta>
              </CommentInfo>
              <div>
                <ActionButton $approve onClick={() => handleApprove(comment._id)}>
                  <Check size={16} />
                </ActionButton>
                <ActionButton onClick={() => handleReject(comment._id)}>
                  <X size={16} />
                </ActionButton>
              </div>
            </CommentItem>
          ))}
        </CommentList>
      )}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </CommentModerationContainer>
  );
}

export default CommentModeration;
