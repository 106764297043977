import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Settings, Save } from 'lucide-react';
import { animated } from 'react-spring';
import { useAnimatedMount } from '../hooks/useAnimations';
import LoadingSpinner from './LoadingSpinner';

const SettingsContainer = styled(animated.div)`
  max-width: 600px;
  margin: 60px auto 0;
  padding: 20px;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const ReadOnlyInput = styled(Input)`
  background-color: ${props => props.theme.cardBackground};
  cursor: not-allowed;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

const BackButton = styled(Button)`
  margin-top: 20px;
  background-color: ${props => props.theme.secondary};

  &:hover {
    background-color: ${props => props.theme.accent2};
  }
`;

const ErrorMessage = styled.p`
  color: ${props => props.theme.error};
  text-align: center;
`;

const UserSettings = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const animationProps = useAnimatedMount();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      if (newPassword !== confirmPassword) {
        throw new Error('Passwords do not match');
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/auth/change-password`, { newPassword }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setNewPassword('');
      setConfirmPassword('');
      // Show success message
      alert('Password changed successfully');
    } catch (error) {
      console.error('Error changing password:', error);
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <SettingsContainer style={animationProps}>
      <Title><Settings /> User Settings</Title>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="username">Username</Label>
          <ReadOnlyInput
            id="username"
            type="text"
            value={userData.username}
            readOnly
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <ReadOnlyInput
            id="email"
            type="email"
            value={userData.email}
            readOnly
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="newPassword">New Password</Label>
          <Input
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="confirmPassword">Confirm New Password</Label>
          <Input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </FormGroup>
        <Button type="submit">
          <Save size={16} /> Change Password
        </Button>
      </Form>
      <BackButton onClick={() => navigate('/dashboard')}>
        Back to Dashboard
      </BackButton>
    </SettingsContainer>
  );
};

export default UserSettings;
