// frontend/src/components/VideoSection.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../utils/api';
import VideoList from './VideoList';
import Alert from './Alert';
import LoadingSpinner from './LoadingSpinner';

const VideoSectionContainer = styled.div`
  margin-top: 20px;
`;

const NoVideosMessage = styled.p`
  text-align: center;
  color: ${props => props.theme.textSecondary};
  font-style: italic;
`;

function VideoSection({ setToast }) {
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRecommendedVideos();
  }, []);

  const fetchRecommendedVideos = async () => {
    try {
      setLoading(true);
      const response = await api.get('/videos/recommended');
      setRecommendedVideos(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching recommended videos:', error);
      setError('Failed to load recommended videos. Please try again later.');
      setToast({ message: 'Error fetching recommended videos. Please try again.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Alert title="Error" description={error} />;
  }

  return (
    <VideoSectionContainer>
      {recommendedVideos.length > 0 ? (
        <VideoList videos={recommendedVideos} />
      ) : (
        <NoVideosMessage>No recommended videos available at the moment.</NoVideosMessage>
      )}
    </VideoSectionContainer>
  );
}

export default VideoSection;
