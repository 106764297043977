import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { X } from 'lucide-react';

const ToastContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const ToastMessage = styled.div`
  background-color: ${props => props.theme[props.$type]};
  color: ${props => props.theme.text};
  padding: 12px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  opacity: ${props => (props.$visible ? 1 : 0)};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  margin-left: 10px;
`;

const Toast = ({ message, type = 'success', duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <ToastContainer>
      <ToastMessage $type={type} $visible={visible}>
        {message}
        <CloseButton onClick={() => setVisible(false)}>
          <X size={16} />
        </CloseButton>
      </ToastMessage>
    </ToastContainer>
  );
};

export default Toast;
