import React, { createContext, useContext, useEffect, useState } from 'react';

const AccessibilityContext = createContext();

export const useAccessibility = () => useContext(AccessibilityContext);

export const AccessibilityProvider = ({ children }) => {
  const [focusableElements, setFocusableElements] = useState([]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab') {
        const focusable = document.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        setFocusableElements(Array.from(focusable));
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const moveFocus = (direction) => {
    const currentFocusIndex = focusableElements.indexOf(document.activeElement);
    let nextIndex;

    if (direction === 'forward') {
      nextIndex = (currentFocusIndex + 1) % focusableElements.length;
    } else {
      nextIndex = (currentFocusIndex - 1 + focusableElements.length) % focusableElements.length;
    }

    focusableElements[nextIndex].focus();
  };

  const value = {
    moveFocus,
  };

  return (
    <AccessibilityContext.Provider value={value}>
      {children}
    </AccessibilityContext.Provider>
  );
};

export default AccessibilityProvider;
