// frontend/src/theme.js

const baseTheme = {
  transition: 'all 0.3s ease',
  borderRadius: '8px',
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
};

export const themes = {
  blueDark: {
    ...baseTheme,
    name: 'blueDark',
    mode: 'dark',
    background: '#0A1929',
    cardBackground: '#132F4C',
    cardBackgroundHover: '#1E3A5F',
    primary: '#5090D3',
    secondary: '#B2BAC2',
    accent1: '#FFD700',
    accent2: '#4DD0E1',
    accent3: '#9C27B0',
    text: '#FFFFFF',
    textLight: '#F0F0F0',
    textSecondary: '#D0D7DE',
    textDark: '#0A1929',
    error: '#FF6B6B',
    success: '#4CAF50',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    chartColors: ['#FFD700', '#4DD0E1', '#9C27B0', '#FF6B6B', '#4CAF50'],
    recommendationBorder: '2px solid #5090D3',
    recommendationBackground: 'rgba(80, 144, 211, 0.1)',
  },
  feminineLight: {
    ...baseTheme,
    name: 'feminineLight',
    mode: 'light',
    background: '#FFF0F5',
    cardBackground: '#FFFFFF',
    cardBackgroundHover: '#FCE4EC',
    primary: '#C71585',
    secondary: '#FF69B4',
    accent1: '#FF1493',
    accent2: '#DB7093',
    accent3: '#FFA07A',
    text: '#333333',
    textLight: '#4B0082',
    textSecondary: '#6A5ACD',
    textDark: '#000000',
    error: '#FF69B4',
    success: '#2E8B57',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
    chartColors: ['#FF1493', '#DB7093', '#FFA07A', '#FF69B4', '#2E8B57'],
    recommendationBorder: '2px solid #C71585',
    recommendationBackground: 'rgba(199, 21, 133, 0.05)',
  },
  feminineDark: {
    ...baseTheme,
    name: 'feminineDark',
    mode: 'dark',
    background: '#2E0854',
    cardBackground: '#3C096C',
    cardBackgroundHover: '#4C0B5F',
    primary: '#FF00FF',
    secondary: '#DA70D6',
    accent1: '#FF69B4',
    accent2: '#FFB6C1',
    accent3: '#FFA07A',
    text: '#FFFFFF',
    textLight: '#F0E6FF',
    textSecondary: '#D8BFD8',
    textDark: '#E6E6FA',
    error: '#FF1493',
    success: '#00FA9A',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    chartColors: ['#FF69B4', '#FFB6C1', '#FFA07A', '#FF1493', '#00FA9A'],
    recommendationBorder: '2px solid #FF00FF',
    recommendationBackground: 'rgba(255, 0, 255, 0.1)',
  },
  beautifulLight: {
    ...baseTheme,
    name: 'beautifulLight',
    mode: 'light',
    background: '#F0F8FF',
    cardBackground: '#FFFFFF',
    cardBackgroundHover: '#E6F3FF',
    primary: '#4169E1',
    secondary: '#87CEEB',
    accent1: '#FFD700',
    accent2: '#98FB98',
    accent3: '#DDA0DD',
    text: '#333333',
    textLight: '#4169E1',
    textSecondary: '#5F9EA0',
    textDark: '#191970',
    error: '#DC143C',
    success: '#32CD32',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
    chartColors: ['#4169E1', '#87CEEB', '#FFD700', '#98FB98', '#DDA0DD'],
    recommendationBorder: '2px solid #4169E1',
    recommendationBackground: 'rgba(65, 105, 225, 0.05)',
  },
  beautifulDark: {
    ...baseTheme,
    name: 'beautifulDark',
    mode: 'dark',
    background: '#1A1A2E',
    cardBackground: '#16213E',
    cardBackgroundHover: '#0F3460',
    primary: '#E94560',
    secondary: '#533483',
    accent1: '#FFD700',
    accent2: '#00CED1',
    accent3: '#FF69B4',
    text: '#FFFFFF',
    textLight: '#F0F0F0',
    textSecondary: '#B0C4DE',
    textDark: '#E0FFFF',
    error: '#FF6347',
    success: '#00FF7F',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
    chartColors: ['#E94560', '#533483', '#FFD700', '#00CED1', '#FF69B4'],
    recommendationBorder: '2px solid #E94560',
    recommendationBackground: 'rgba(233, 69, 96, 0.1)',
  },
};

export const validateTheme = (theme) => {
  const requiredProperties = [
    'name', 'mode', 'background', 'cardBackground', 'cardBackgroundHover',
    'primary', 'secondary', 'accent1', 'accent2', 'accent3',
    'text', 'textLight', 'textSecondary', 'textDark',
    'error', 'success', 'boxShadow', 'chartColors',
    'recommendationBorder', 'recommendationBackground'
  ];

  const missingProperties = requiredProperties.filter(prop => !(prop in theme));

  if (missingProperties.length > 0) {
    console.error(`Theme is missing required properties: ${missingProperties.join(', ')}`);
    return { ...themes.blueDark, ...theme }; // Fallback to blueDark for missing properties
  }

  return theme;
};

export default themes.blueDark;
