// frontend/src/ThemeContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { themes, validateTheme } from './theme';

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState('blueDark');
  const [currentTheme, setCurrentTheme] = useState(themes.blueDark);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme && themes[savedTheme]) {
      setThemeName(savedTheme);
    }
  }, []);

  useEffect(() => {
    const newTheme = themes[themeName];
    const validatedTheme = validateTheme(newTheme);
    setCurrentTheme(validatedTheme);
    localStorage.setItem('theme', themeName);
  }, [themeName]);

  const setTheme = (name) => {
    if (themes[name]) {
      setThemeName(name);
    } else {
      console.error(`Theme "${name}" not found`);
    }
  };

  const toggleDarkMode = () => {
    const currentMode = currentTheme.mode;
    const newMode = currentMode === 'light' ? 'dark' : 'light';
    const newThemeName = Object.keys(themes).find(
      key => themes[key].mode === newMode && themes[key].name.includes(currentTheme.name.replace('Light', '').replace('Dark', ''))
    );
    if (newThemeName) {
      setTheme(newThemeName);
    }
  };

  return (
    <ThemeContext.Provider value={{ themeName, setTheme, toggleDarkMode, currentTheme }}>
      <StyledThemeProvider theme={currentTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
