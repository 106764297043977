// frontend/src/components/Recommendations.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Award, ThumbsUp, ThumbsDown, ChevronUp, ChevronDown } from 'lucide-react';
import { animated, useSpring, useTransition } from 'react-spring';
import { useAnimatedMount } from '../hooks/useAnimations';
import LoadingSpinner from './LoadingSpinner';

const AnimatedCard = animated(styled.section`
  background-color: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing.medium};
  box-shadow: ${props => props.theme.boxShadow};
  transition: ${props => props.theme.transition};

  &:hover {
    background-color: ${props => props.theme.cardBackgroundHover};
  }
`);

const CardTitle = styled.h2`
  color: ${props => props.theme.primary};
  border-bottom: 2px solid ${props => props.theme.primary};
  padding-bottom: ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  font-size: 1.2rem;
  margin-bottom: ${props => props.theme.spacing.medium};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  text-align: center;
`;

const MessageIcon = styled.div`
  font-size: 3rem;
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.primary};
`;

const MessageText = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.text};
`;

const RecommendationList = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

const RecommendationItem = styled(animated.li)`
  margin-bottom: ${props => props.theme.spacing.medium};
  padding: ${props => props.theme.spacing.medium};
  background-color: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  border-left: 4px solid ${props => props.$color || props.theme.primary};
  position: relative;
  overflow: hidden;
  transition: ${props => props.theme.transition};
`;

const RecommendationContent = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.text};
  margin: 0 0 ${props => props.theme.spacing.small} 0;
`;

const RecommendationActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${props => props.theme.spacing.small};
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.text};
  transition: ${props => props.theme.transition};
  padding: ${props => props.theme.spacing.small};
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};

  &:hover {
    color: ${props => props.theme.primary};
    background-color: ${props => props.theme.cardBackgroundHover};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const FeedbackMessage = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.accent2};
  margin-left: ${props => props.theme.spacing.small};
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.text};
  position: absolute;
  right: ${props => props.theme.spacing.small};
  top: ${props => props.theme.spacing.small};
  transition: ${props => props.theme.transition};
  transform: ${props => props.$expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const AdditionalContent = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  padding-top: ${props => props.theme.spacing.small};
  border-top: 1px solid ${props => props.theme.secondary};
  font-size: 0.85rem;
  color: ${props => props.theme.textSecondary};
`;

const Recommendations = ({ recommendations, currentGame, isProfilePrivate, hasNoGameHistory, onFeedback, setCurrentRecommendation, setFeedbackModalOpen, loading }) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [feedbackStates, setFeedbackStates] = useState({});
  const animationProps = useAnimatedMount();

  const handleFeedback = (index, isHelpful) => {
    if (isHelpful) {
      onFeedback(recommendations[index], true);
      setFeedbackStates(prev => ({
        ...prev,
        [index]: 'helpful'
      }));
    } else {
      setCurrentRecommendation(recommendations[index].text);
      setFeedbackModalOpen(true);
    }
  };

  const toggleExpand = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const itemTransitions = useTransition(recommendations, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-20px)' },
    trail: 100,
  });

  if (loading) {
    return (
      <AnimatedCard style={animationProps}>
        <CardTitle><Award /> Recommendations</CardTitle>
        <LoadingSpinner />
      </AnimatedCard>
    );
  }

  if (isProfilePrivate || hasNoGameHistory || recommendations.length === 0) {
    return (
      <AnimatedCard style={animationProps}>
        <CardTitle><Award /> Recommendations</CardTitle>
        <MessageContainer>
          <MessageIcon><Award /></MessageIcon>
          <MessageText>
            {isProfilePrivate ? "This profile is private. Please make your profile public to view recommendations." :
             hasNoGameHistory ? "No game history found for this profile." :
             `We don't have any recommendations for ${currentGame.toUpperCase()} at the moment.
              Check out our Resource Hub for valuable information and tools to improve your gameplay.`}
          </MessageText>
        </MessageContainer>
      </AnimatedCard>
    );
  }

  return (
    <AnimatedCard style={animationProps}>
      <CardTitle><Award /> Recommendations</CardTitle>
      <RecommendationList>
        {itemTransitions((style, recommendation, _, index) => (
          <RecommendationItem
            style={style}
            key={index}
            $color={recommendation.color || `hsl(${index * 60}, 70%, 50%)`}
          >
            <RecommendationContent>{recommendation.text}</RecommendationContent>
            <RecommendationActions>
              <ActionButton
                onClick={() => handleFeedback(index, true)}
                aria-label="Helpful"
                disabled={feedbackStates[index]}
              >
                <ThumbsUp size={16} />
                Helpful
              </ActionButton>
              <ActionButton
                onClick={() => handleFeedback(index, false)}
                aria-label="Not Helpful"
                disabled={feedbackStates[index]}
              >
                <ThumbsDown size={16} />
                Not Helpful
              </ActionButton>
              {feedbackStates[index] && (
                <FeedbackMessage>
                  {feedbackStates[index] === 'helpful' ? 'Thanks for the feedback!' : 'We\'ll improve this recommendation.'}
                </FeedbackMessage>
              )}
            </RecommendationActions>
            {recommendation.additionalContent && (
              <>
                <ExpandButton
                  onClick={() => toggleExpand(index)}
                  $expanded={expandedItems[index]}
                >
                  {expandedItems[index] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                </ExpandButton>
                {expandedItems[index] && (
                  <AdditionalContent>
                    {recommendation.additionalContent}
                  </AdditionalContent>
                )}
              </>
            )}
          </RecommendationItem>
        ))}
      </RecommendationList>
    </AnimatedCard>
  );
};

export default Recommendations;
