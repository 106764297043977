// frontend/src/components/AdminComponents/VideoForm.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Plus, Edit } from 'lucide-react';
import TagSelector from './TagSelector';

const Form = styled(motion.form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
  background-color: ${props => props.theme.cardBackground};
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
  color: ${props => props.theme.textSecondary};
  font-weight: 600;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 6px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 6px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  font-size: 1rem;
  min-height: 120px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const Select = styled.select`
  padding: 12px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 6px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primary};
  }
`;

const Button = styled(motion.button)`
  padding: 12px 20px;
  background-color: ${props => props.theme.primary};
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

const predefinedCategories = ['mid-lane', 'top-lane', 'jungle', 'adc', 'support', 'macro', 'micro', 'general'];
const predefinedSkillLevels = ['beginner', 'intermediate', 'advanced'];

function VideoForm({ onSubmit, editingVideo, setEditingVideo }) {
  const [video, setVideo] = useState({
    youtubeUrl: '',
    title: '',
    description: '',
    category: '',
    skillLevel: '',
    tags: [],
  });

  useEffect(() => {
    if (editingVideo) {
      setVideo(editingVideo);
    } else {
      setVideo({
        youtubeUrl: '',
        title: '',
        description: '',
        category: '',
        skillLevel: '',
        tags: [],
      });
    }
  }, [editingVideo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVideo({ ...video, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(video, !!editingVideo);
    setVideo({
      youtubeUrl: '',
      title: '',
      description: '',
      category: '',
      skillLevel: '',
      tags: [],
    });
    setEditingVideo(null);
  };

  return (
    <Form
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onSubmit={handleSubmit}
    >
      <InputGroup>
        <Label htmlFor="youtubeUrl">YouTube Video URL</Label>
        <Input
          id="youtubeUrl"
          type="text"
          name="youtubeUrl"
          value={video.youtubeUrl}
          onChange={handleInputChange}
          placeholder="Enter YouTube Video URL"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="title">Title</Label>
        <Input
          id="title"
          type="text"
          name="title"
          value={video.title}
          onChange={handleInputChange}
          placeholder="Enter video title"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="description">Description</Label>
        <TextArea
          id="description"
          name="description"
          value={video.description}
          onChange={handleInputChange}
          placeholder="Enter video description"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="category">Category</Label>
        <Select
          id="category"
          name="category"
          value={video.category}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Category</option>
          {predefinedCategories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </Select>
      </InputGroup>
      <InputGroup>
        <Label htmlFor="skillLevel">Skill Level</Label>
        <Select
          id="skillLevel"
          name="skillLevel"
          value={video.skillLevel}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Skill Level</option>
          {predefinedSkillLevels.map(level => (
            <option key={level} value={level}>{level}</option>
          ))}
        </Select>
      </InputGroup>
      <InputGroup>
        <Label htmlFor="tags">Tags</Label>
        <TagSelector
          selectedTags={video.tags}
          onChange={(tags) => setVideo({ ...video, tags })}
        />
      </InputGroup>
      <Button
        type="submit"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {editingVideo ? <><Edit size={18} /> Update Video</> : <><Plus size={18} /> Add Video</>}
      </Button>
    </Form>
  );
}

export default VideoForm;
