// AdminComponents/TagSelector.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const TagChip = styled(motion.button)`
  background-color: ${props => props.selected ? props.theme.primary : props.theme.cardBackground};
  color: ${props => props.selected ? props.theme.text : props.theme.textSecondary};
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.accent1};
    color: ${props => props.theme.text};
  }
`;

const predefinedTags = ['Macro', 'Micro', 'Laning', 'Teamfighting', 'Map Awareness', 'Farming', 'Vision Control', 'Champion Specific'];

function TagSelector({ selectedTags, onChange }) {
  const toggleTag = (tag) => {
    const updatedTags = selectedTags.includes(tag)
      ? selectedTags.filter(t => t !== tag)
      : [...selectedTags, tag];
    onChange(updatedTags);
  };

  return (
    <TagContainer>
      {predefinedTags.map(tag => (
        <TagChip
          key={tag}
          selected={selectedTags.includes(tag)}
          onClick={() => toggleTag(tag)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {tag}
        </TagChip>
      ))}
    </TagContainer>
  );
}

export default TagSelector;
