// AdminComponents/VideoList.js
import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import VideoCard from './VideoCard';

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

function VideoList({ videos, onEdit, onDelete }) {
  return (
    <ListContainer>
      <AnimatePresence>
        {videos.map(video => (
          <VideoCard
            key={video._id}
            video={video}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </AnimatePresence>
    </ListContainer>
  );
}

export default VideoList;
