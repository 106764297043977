// frontend/src/components/GameProfileSelector.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight, PlusCircle, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const GameButtonContainer = styled(animated.div)`
  display: flex;
  align-items: center;
`;

const GameButton = styled.button`
  background-color: ${props => props.$active ? props.theme.primary : props.theme.cardBackground};
  color: ${props => props.$active ? props.theme.text : props.theme.textSecondary};
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 8px;
  cursor: ${props => props.$available ? 'pointer' : 'not-allowed'};
  transition: all 0.3s ease;
  opacity: ${props => props.$available ? 1 : 0.5};
  position: relative;

  &:hover {
    background-color: ${props => props.$available ? props.theme.primary : props.theme.cardBackground};
    color: ${props => props.$available ? props.theme.text : props.theme.textSecondary};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.accent1};
    outline-offset: 2px;
  }
`;

const UnavailableIcon = styled(AlertCircle)`
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${props => props.theme.error};
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 2px solid ${props => props.theme.accent1};
    outline-offset: 2px;
  }

  &:disabled {
    color: ${props => props.theme.textSecondary};
    cursor: not-allowed;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;

  ${GameButton}:hover & {
    opacity: 1;
  }
`;

const GameProfileSelector = ({ currentGame, setCurrentGame, gameProfiles, setToast }) => {
  const [hoveredGame, setHoveredGame] = useState(null);
  const games = ['lol', 'valorant', 'tft', 'lor'];
  const availableGames = ['lol'];

  const handleGameChange = (game) => {
    if (availableGames.includes(game)) {
      setCurrentGame(game);
    } else {
      setToast({
        message: `${game.toUpperCase()} data is not available yet. We're waiting for a development Riot API key.`,
        type: 'info'
      });
    }
  };

  const handlePrevGame = () => {
    const currentIndex = games.indexOf(currentGame);
    if (currentIndex > 0) {
      handleGameChange(games[currentIndex - 1]);
    }
  };

  const handleNextGame = () => {
    const currentIndex = games.indexOf(currentGame);
    if (currentIndex < games.length - 1) {
      handleGameChange(games[currentIndex + 1]);
    }
  };

  const transitions = useTransition(currentGame, {
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-50px)' },
  });

  return (
    <SelectorContainer>
      <IconButton onClick={handlePrevGame} disabled={games.indexOf(currentGame) === 0} aria-label="Previous game">
        <ChevronLeft size={24} />
      </IconButton>
      {transitions((style, item) => (
        <GameButtonContainer style={style}>
          {games.map(game => (
            <GameButton
              key={game}
              $active={game === item}
              $available={availableGames.includes(game)}
              onClick={() => handleGameChange(game)}
              onMouseEnter={() => setHoveredGame(game)}
              onMouseLeave={() => setHoveredGame(null)}
              aria-label={`Select ${game.toUpperCase()}`}
              aria-pressed={game === item}
            >
              {game.toUpperCase()}
              {!availableGames.includes(game) && <UnavailableIcon size={14} />}
              {!availableGames.includes(game) && hoveredGame === game && (
                <Tooltip>
                  Waiting for development Riot API key
                </Tooltip>
              )}
            </GameButton>
          ))}
        </GameButtonContainer>
      ))}
      <IconButton onClick={handleNextGame} disabled={games.indexOf(currentGame) === games.length - 1} aria-label="Next game">
        <ChevronRight size={24} />
      </IconButton>
      <IconButton as={Link} to="/profile-management" aria-label="Add new game profile">
        <PlusCircle size={24} />
      </IconButton>
    </SelectorContainer>
  );
};

export default GameProfileSelector;
