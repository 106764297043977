// frontend/src/components/CoachingVideos.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Play, Clock, Tag, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import LoadingSpinner from './LoadingSpinner';
import Alert from './Alert';

const CoachingVideosContainer = styled(motion.div)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  color: ${props => props.theme.primary};
  margin-bottom: 30px;
  font-size: 2.5rem;
  text-align: center;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

const Select = styled.select`
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.secondary};
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover, &:focus {
    border-color: ${props => props.theme.primary};
    outline: none;
  }
`;

const VideoGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
`;

const VideoCard = styled(motion(Link))`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: ${props => props.theme.text};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
`;

const Thumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoInfo = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const VideoTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 1.2rem;
  color: ${props => props.theme.primary};
`;

const VideoMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin-top: auto;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const NoVideosMessage = styled.p`
  text-align: center;
  color: ${props => props.theme.textSecondary};
  font-size: 1.2rem;
  margin-top: 40px;
`;

function CoachingVideos() {
  const [videos, setVideos] = useState([]);
  const [category, setCategory] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchVideos();
  }, [category, skillLevel]);

  const fetchVideos = async () => {
    try {
      setLoading(true);
      setError(null);
      const params = new URLSearchParams();
      if (category) params.append('category', category);
      if (skillLevel) params.append('skillLevel', skillLevel);

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/videos?${params.toString()}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVideos(response.data);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setError('Failed to load videos. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Alert title="Error" description={error} />;
  }

  return (
    <CoachingVideosContainer
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <Title>Coaching Videos</Title>
      <FilterContainer>
        <Select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">All Categories</option>
          <option value="mid-lane">Mid Lane</option>
          <option value="top-lane">Top Lane</option>
          <option value="jungle">Jungle</option>
          <option value="adc">ADC</option>
          <option value="support">Support</option>
          <option value="macro">Macro</option>
          <option value="micro">Micro</option>
          <option value="general">General</option>
        </Select>
        <Select value={skillLevel} onChange={(e) => setSkillLevel(e.target.value)}>
          <option value="">All Skill Levels</option>
          <option value="beginner">Beginner</option>
          <option value="intermediate">Intermediate</option>
          <option value="advanced">Advanced</option>
        </Select>
      </FilterContainer>
      {videos.length > 0 ? (
        <VideoGrid>
          <AnimatePresence>
            {videos.map(video => (
              <VideoCard
                key={video._id}
                to={`/videos/${video._id}`}
                variants={cardVariants}
              >
                <ThumbnailContainer>
                  <Thumbnail src={video.thumbnailUrl} alt={video.title} />
                </ThumbnailContainer>
                <VideoInfo>
                  <VideoTitle>{video.title}</VideoTitle>
                  <VideoMeta>
                    <MetaItem><Clock size={14} /> {Math.floor(video.duration / 60)}:{video.duration % 60 < 10 ? '0' : ''}{video.duration % 60}</MetaItem>
                    <MetaItem><Tag size={14} /> {video.category}</MetaItem>
                    <MetaItem><Play size={14} /> {video.viewCount} views</MetaItem>
                  </VideoMeta>
                </VideoInfo>
              </VideoCard>
            ))}
          </AnimatePresence>
        </VideoGrid>
      ) : (
        <NoVideosMessage>No videos found for the selected filters.</NoVideosMessage>
      )}
    </CoachingVideosContainer>
  );
}

export default CoachingVideos;
