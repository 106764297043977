import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { RefreshCw, Coffee, HelpCircle } from 'lucide-react';
import Alert from './Alert';
import LoadingSpinner from './LoadingSpinner';
import GameProfileSelector from './GameProfileSelector';
import PerformanceTrends from './PerformanceTrends';
import Recommendations from './Recommendations';
import ResourceHub from './ResourceHub';
import FeedbackModal from './FeedbackModal';
import IntroModal from './IntroModal';
import WelcomeOverlay from './WelcomeOverlay';
import VideoSection from './VideoSection';
import { useTheme } from '../ThemeContext';

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 60px auto 0;
  padding: ${props => props.theme.spacing.large};
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  position: relative;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.medium};
`;

const HelpButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.background};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.large};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  border-radius: ${props => props.theme.borderRadius};
  font-weight: 600;
  cursor: pointer;
  transition: ${props => props.theme.transition};

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

const LastUpdated = styled.p`
  text-align: center;
  color: ${props => props.theme.textSecondary};
  font-size: 0.8rem;
  margin-top: ${props => props.theme.spacing.large};
`;

const BuyMeCoffeeButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: ${props => props.theme.accent1};
  color: ${props => props.theme.background};
  text-decoration: none;
  border-radius: ${props => props.theme.borderRadius};
  font-weight: 600;
  margin-top: ${props => props.theme.spacing.large};
  transition: ${props => props.theme.transition};

  &:hover {
    background-color: ${props => props.theme.accent2};
  }
`;

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [currentGame, setCurrentGame] = useState('lol');
  const [isProfilePrivate, setIsProfilePrivate] = useState(false);
  const [hasNoGameHistory, setHasNoGameHistory] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [introModalOpen, setIntroModalOpen] = useState(false);
  const [showWelcomeOverlay, setShowWelcomeOverlay] = useState(false);
  const [currentRecommendation, setCurrentRecommendation] = useState(null);
  const { currentTheme } = useTheme();

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      setShowWelcomeOverlay(true);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      setIsProfilePrivate(false);
      setHasNoGameHistory(false);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserData(userResponse.data);

      const gameProfiles = userResponse.data.gameProfiles;
      if (!gameProfiles || Object.keys(gameProfiles).length === 0) {
        setError('No game profiles found. Please add a game profile.');
        setLoading(false);
        return;
      }

      if (!gameProfiles[currentGame]) {
        const availableGames = Object.keys(gameProfiles);
        if (availableGames.length > 0) {
          setCurrentGame(availableGames[0]);
        } else {
          throw new Error('No game profiles found. Please add a game profile.');
        }
      }

      const gameProfile = gameProfiles[currentGame];
      const [gameName, tagLine] = gameProfile.split('#');

      try {
        const playerResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/player/${currentGame}/analyze/${encodeURIComponent(gameName)}/${encodeURIComponent(tagLine)}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (playerResponse.data.isPrivate) {
          setIsProfilePrivate(true);
          setPlayerData(null);
        } else if (playerResponse.data.noGameHistory) {
          setHasNoGameHistory(true);
          setPlayerData(null);
        } else {
          setPlayerData(playerResponse.data);
          setIsProfilePrivate(false);
          setHasNoGameHistory(false);
        }
      } catch (playerError) {
        console.error('Error fetching player data:', playerError);
        setError(`Error fetching player data: ${playerError.message}`);
        setPlayerData(null);
      }

      setLastUpdated(new Date());
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.error || error.message || 'An error occurred during analysis');
    } finally {
      setLoading(false);
    }
  }, [currentGame]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRecommendationFeedback = async (recommendation, isHelpful) => {
    if (isHelpful) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/feedback/upvote`, { recommendation });
        // Show a brief "Thank you" message or update UI accordingly
      } catch (error) {
        console.error('Error sending upvote:', error);
      }
    } else {
      setCurrentRecommendation(recommendation);
      setFeedbackModalOpen(true);
    }
  };

  const handleFeedbackSubmit = async (feedback) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/feedback/downvote`, { recommendation: currentRecommendation, feedback });
      setFeedbackModalOpen(false);
      // Show a "Feedback sent" message or update UI accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const handleWelcomeOverlayClose = () => {
    setShowWelcomeOverlay(false);
    localStorage.setItem('hasSeenWelcome', 'true');
  };

  return (
    <DashboardContainer>
      <TopBar>
        <HelpButton onClick={() => setIntroModalOpen(true)}>
          <HelpCircle size={20} />
        </HelpButton>
      </TopBar>

      <GameProfileSelector
        currentGame={currentGame}
        setCurrentGame={setCurrentGame}
        gameProfiles={userData?.gameProfiles}
      />

      <Button onClick={fetchData}>
        <RefreshCw size={16} /> Update Analysis
      </Button>

      {error ? (
        <Alert
          title="Error"
          description={error}
          action={{
            label: 'Add Game Profile',
            onClick: () => {/* Navigate to profile management */}
          }}
        />
      ) : (
        <Grid>
          <PerformanceTrends
            playerData={playerData}
            currentGame={currentGame}
            isProfilePrivate={isProfilePrivate}
            hasNoGameHistory={hasNoGameHistory}
            loading={loading}
          />
          <Recommendations
            recommendations={playerData?.recommendations || []}
            currentGame={currentGame}
            isProfilePrivate={isProfilePrivate}
            hasNoGameHistory={hasNoGameHistory}
            onFeedback={handleRecommendationFeedback}
            setCurrentRecommendation={setCurrentRecommendation}
            setFeedbackModalOpen={setFeedbackModalOpen}
            loading={loading}
          />
        </Grid>
      )}

      <ResourceHub currentGame={currentGame} userData={userData} />

      <VideoSection setToast={() => {}} />

      <FeedbackModal
        isOpen={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />

      <IntroModal
        isOpen={introModalOpen}
        onClose={() => setIntroModalOpen(false)}
      />

      <WelcomeOverlay
        isOpen={showWelcomeOverlay}
        onClose={handleWelcomeOverlayClose}
        onWatchIntro={() => {
          setShowWelcomeOverlay(false);
          setIntroModalOpen(true);
        }}
      />

      <LastUpdated>
        Last updated: {lastUpdated ? lastUpdated.toLocaleString() : 'Never'}
        {lastUpdated && new Date() - lastUpdated > 24 * 60 * 60 * 1000 && (
          <span style={{color: currentTheme.error}}> (Data may be outdated. Consider updating.)</span>
        )}
      </LastUpdated>

      <BuyMeCoffeeButton
        href="https://ko-fi.com/miodragmtasic"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Coffee /> Support Website Costs
      </BuyMeCoffeeButton>
    </DashboardContainer>
  );
}

export default Dashboard;
