// components/ProfileManagement.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Plus, Trash2, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Alert from './Alert';
import LoadingSpinner from './LoadingSpinner';
import Toast from './Toast';

const ProfileManagementContainer = styled.div`
  max-width: 800px;
  margin: 60px auto 0;
  padding: 20px;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
`;

const ProfileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
`;

const ProfileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const GameName = styled.span`
  font-weight: bold;
  color: ${props => props.theme.text};
  font-size: 1.1rem;
`;

const ProfileInfo = styled.span`
  color: ${props => props.theme.textSecondary};
  margin-left: 10px;
`;

const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background-color: ${props => props.theme.accent1};
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: ${props => props.theme.secondary};
    cursor: not-allowed;
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.error};
  padding: 6px 12px;

  &:hover {
    background-color: ${props => props.theme.accent3};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: ${props => props.theme.background};
  padding: 20px;
  border-radius: 8px;
`;

const FormTitle = styled.h2`
  color: ${props => props.theme.primary};
  margin-bottom: 15px;
  font-size: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    outline: none;
  }

  &:invalid {
    border-color: ${props => props.$touched ? props.theme.error : props.theme.secondary};
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const gameLabels = {
  lol: 'Summoner Name',
  valorant: 'Riot ID',
  tft: 'Summoner Name',
  lor: 'Riot ID'
};

function ProfileManagement() {
  const [profiles, setProfiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newProfile, setNewProfile] = useState({ game: '', inGameName: '', tagline: '' });
  const [toast, setToast] = useState(null);
  const [touchedFields, setTouchedFields] = useState({ inGameName: false, tagline: false });
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfiles(response.data.gameProfiles);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddProfile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/update-game-profile`,
        { game: newProfile.game, profile: `${newProfile.inGameName}#${newProfile.tagline}` },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProfiles(response.data.gameProfiles);
      setNewProfile({ game: '', inGameName: '', tagline: '' });
      setTouchedFields({ inGameName: false, tagline: false });
      setToast({ message: 'Profile added successfully', type: 'success' });
    } catch (error) {
      console.error('Error adding profile:', error);
      setError(error.response?.data?.error || error.message);
      setToast({ message: 'Failed to add profile', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProfile = async (game) => {
    if (!window.confirm(`Are you sure you want to delete your ${game.toUpperCase()} profile?`)) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/update-game-profile`,
        { game, profile: null },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProfiles(prevProfiles => {
        const updatedProfiles = { ...prevProfiles };
        delete updatedProfiles[game];
        return updatedProfiles;
      });
      setToast({ message: 'Profile deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting profile:', error);
      setError(error.response?.data?.error || error.message);
      setToast({ message: 'Failed to delete profile', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const isValidInput = (input) => /^[a-zA-Z0-9]+$/.test(input);

  const isFormValid = () => {
    return newProfile.game && isValidInput(newProfile.inGameName) && isValidInput(newProfile.tagline);
  };

  const handleInputChange = (e, field) => {
    setNewProfile({ ...newProfile, [field]: e.target.value });
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Alert title="Error" description={error} />;

  return (
    <ProfileManagementContainer>
      <Title>Game Profiles</Title>
      <ProfileList>
        {Object.entries(profiles).map(([game, profile]) => (
          <ProfileItem key={game}>
            <div>
              <GameName>{game.toUpperCase()}</GameName>
              <ProfileInfo>{profile}</ProfileInfo>
            </div>
            <DeleteButton onClick={() => handleDeleteProfile(game)}>
              <Trash2 size={16} /> Delete
            </DeleteButton>
          </ProfileItem>
        ))}
      </ProfileList>
      <Form onSubmit={handleAddProfile}>
        <FormTitle>Add New Profile</FormTitle>
        <InputContainer>
          <Label htmlFor="game">Game</Label>
          <Select
            id="game"
            value={newProfile.game}
            onChange={(e) => setNewProfile({ ...newProfile, game: e.target.value })}
            required
          >
            <option value="">Select Game</option>
            <option value="lol">League of Legends</option>
            <option value="valorant">Valorant</option>
            <option value="tft">Teamfight Tactics</option>
            <option value="lor">Legends of Runeterra</option>
          </Select>
        </InputContainer>
        <InputContainer>
          <Label htmlFor="inGameName">{newProfile.game ? gameLabels[newProfile.game] : "In-Game Name"}</Label>
          <Input
            id="inGameName"
            type="text"
            value={newProfile.inGameName}
            onChange={(e) => handleInputChange(e, 'inGameName')}
            pattern="[a-zA-Z0-9]+"
            required
            $touched={touchedFields.inGameName}
          />
        </InputContainer>
        <InputContainer>
          <Label htmlFor="tagline">Tagline (e.g., NA1)</Label>
          <Input
            id="tagline"
            type="text"
            value={newProfile.tagline}
            onChange={(e) => handleInputChange(e, 'tagline')}
            pattern="[a-zA-Z0-9]+"
            required
            $touched={touchedFields.tagline}
          />
        </InputContainer>
        <ButtonContainer>
          <Button type="button" onClick={() => navigate('/dashboard')}>
            <ChevronLeft size={16} /> Back to Dashboard
          </Button>
          <Button type="submit" disabled={!isFormValid()}>
            <Plus size={16} /> Add Profile
          </Button>
        </ButtonContainer>
      </Form>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </ProfileManagementContainer>
  );
}

export default ProfileManagement;
