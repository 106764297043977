import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAnimatedMount } from '../hooks/useAnimations';
import LoadingSpinner from './LoadingSpinner';
import Toast from './Toast';
import api from '../utils/api';
import VideoForm from './AdminComponents/VideoForm';
import VideoList from './AdminComponents/VideoList';
import CommentModeration from './AdminComponents/CommentModeration';
import IntroVideoSection from './AdminComponents/IntroVideoSection';

const AdminContainer = styled(motion.div)`
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 40px 20px;
`;

const Title = styled.h1`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background-color: ${props => props.$active ? props.theme.primary : props.theme.cardBackground};
  color: ${props => props.$active ? props.theme.text : props.theme.textSecondary};
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.$active ? props.theme.primary : props.theme.accent1};
  }
`;

function AdminPanel() {
  const [videos, setVideos] = useState([]);
  const [toast, setToast] = useState(null);
  const [editingVideo, setEditingVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('videos');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const animationProps = useAnimatedMount();
  const navigate = useNavigate();

  useEffect(() => {
    checkAdminAuth();
  }, []);

  const checkAdminAuth = async () => {
    try {
      const response = await api.get('/auth/check-admin');
      if (response.data.isAdmin) {
        setIsAuthenticated(true);
        fetchVideos();
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Admin authentication error:', error);
      navigate('/login');
    }
  };

  const fetchVideos = async () => {
    try {
      setLoading(true);
      const response = await api.get('/videos');
      setVideos(response.data);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setToast({ message: 'Error fetching videos', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleVideoSubmit = async (videoData, isEditing) => {
    try {
      setLoading(true);
      if (isEditing) {
        await api.put(`/videos/${editingVideo._id}`, videoData);
        setToast({ message: 'Video updated successfully', type: 'success' });
      } else {
        await api.post('/videos', videoData);
        setToast({ message: 'Video created successfully', type: 'success' });
      }
      setEditingVideo(null);
      fetchVideos();
    } catch (error) {
      console.error('Error saving video:', error);
      setToast({ message: 'Error saving video', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (video) => {
    setEditingVideo(video);
  };

  const handleDelete = async (videoId) => {
    if (window.confirm('Are you sure you want to delete this video?')) {
      try {
        setLoading(true);
        await api.delete(`/videos/${videoId}`);
        setToast({ message: 'Video deleted successfully', type: 'success' });
        fetchVideos();
      } catch (error) {
        console.error('Error deleting video:', error);
        setToast({ message: 'Error deleting video', type: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  if (!isAuthenticated) {
    return <LoadingSpinner />;
  }

  return (
    <AdminContainer {...animationProps}>
      <Title>Admin Panel</Title>
      <TabContainer>
        <Tab $active={activeTab === 'videos'} onClick={() => setActiveTab('videos')}>
          Videos
        </Tab>
        <Tab $active={activeTab === 'comments'} onClick={() => setActiveTab('comments')}>
          Comments
        </Tab>
        <Tab $active={activeTab === 'intro'} onClick={() => setActiveTab('intro')}>
          Intro Video
        </Tab>
      </TabContainer>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {activeTab === 'videos' && (
            <>
              <VideoForm
                onSubmit={handleVideoSubmit}
                editingVideo={editingVideo}
                setEditingVideo={setEditingVideo}
              />
              <VideoList
                videos={videos}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            </>
          )}
          {activeTab === 'comments' && (
            <CommentModeration />
          )}
          {activeTab === 'intro' && (
            <IntroVideoSection
              videos={videos}
              fetchVideos={fetchVideos}
            />
          )}
        </>
      )}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </AdminContainer>
  );
}

export default AdminPanel;
