// frontend/src/components/CommentSection.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Send } from 'lucide-react';
import LoadingSpinner from './LoadingSpinner';
import Alert from './Alert';

const CommentSectionContainer = styled.div`
  margin-top: 20px;
`;

const CommentForm = styled.form`
  display: flex;
  margin-bottom: 20px;
`;

const CommentInput = styled.textarea`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  resize: vertical;
  min-height: 80px;
`;

const SubmitButton = styled.button`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }
`;

const CommentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CommentItem = styled.li`
  background-color: ${props => props.theme.background};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
`;

const CommentAuthor = styled.span`
  font-weight: bold;
  color: ${props => props.theme.primary};
`;

const CommentText = styled.p`
  margin-top: 5px;
`;

function CommentSection({ videoId }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchComments();
  }, [videoId]);

  const fetchComments = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/comments/${videoId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setError('Failed to load comments. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/comments`, {
        videoId,
        text: newComment
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setNewComment('');
      alert('Comment submitted for approval');
      // Optionally, you can refresh the comments here if you want to show pending comments
      // fetchComments();
    } catch (error) {
      console.error('Error submitting comment:', error);
      alert('Failed to submit comment. Please try again.');
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Alert title="Error" description={error} />;
  }

  return (
    <CommentSectionContainer>
      <h2>Comments</h2>
      <CommentForm onSubmit={handleSubmit}>
        <CommentInput
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Write a comment..."
          required
        />
        <SubmitButton type="submit">
          <Send size={18} />
        </SubmitButton>
      </CommentForm>
      <CommentList>
        {comments.map((comment) => (
          <CommentItem key={comment._id}>
            <CommentAuthor>{comment.author}</CommentAuthor>
            <CommentText>{comment.text}</CommentText>
          </CommentItem>
        ))}
      </CommentList>
    </CommentSectionContainer>
  );
}

export default CommentSection;
