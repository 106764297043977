// frontend/src/components/VideoPlayer.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import LoadingSpinner from './LoadingSpinner';
import Alert from './Alert';
import CommentSection from './CommentSection';

const VideoContainer = styled.div`
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 20px;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
`;

const StyledYouTube = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoTitle = styled.h1`
  color: ${props => props.theme.primary};
  margin-bottom: 10px;
  font-size: 1.8rem;
`;

const VideoDescription = styled.p`
  color: ${props => props.theme.text};
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.5;
`;

function VideoPlayer() {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchVideo();
  }, [id]);

  const fetchVideo = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/videos/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVideo(response.data);
    } catch (error) {
      console.error('Error fetching video:', error);
      setError('Failed to load video. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Alert title="Error" description={error} />;
  }

  if (!video) {
    return <Alert title="Error" description="Video not found" />;
  }

  return (
    <VideoContainer>
      <VideoWrapper>
        <StyledYouTube
          videoId={video.youtubeId}
          opts={{
            width: '100%',
            height: '100%',
            playerVars: {
              autoplay: 0,
            },
          }}
        />
      </VideoWrapper>
      <VideoTitle>{video.title}</VideoTitle>
      <VideoDescription>{video.description}</VideoDescription>
      <CommentSection videoId={id} />
    </VideoContainer>
  );
}

export default VideoPlayer;
