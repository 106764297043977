// components/ResourceHub.js
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { ExternalLink, Info } from 'lucide-react';
import { animated, useSpring } from 'react-spring';
import { useAnimatedMount } from '../hooks/useAnimations';

const ResourceHubContainer = styled(animated.div)`
  margin-top: 20px;
`;

const ResourceHubTitle = styled.h2`
  color: ${props => props.theme.primary};
  border-bottom: 2px solid ${props => props.theme.primary};
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const ResourceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const ResourceCard = React.memo(({ resource, onHover, userData, currentGame }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const getResourceUrl = () => {
    if (resource.dynamicUrl && userData && userData.gameProfiles) {
      const gameProfile = userData.gameProfiles[currentGame];
      if (gameProfile) {
        const [gameName, tagLine] = gameProfile.split('#');
        return resource.dynamicUrl(gameName, tagLine);
      }
    }
    return resource.url;
  };

  return (
    <ResourceCardContainer
      onMouseEnter={() => onHover(resource.name)}
      onMouseLeave={() => onHover(null)}
    >
      <ResourceIcon>
        {!imageError ? (
          <ResourceImage
            src={resource.icon}
            alt={`${resource.name} icon`}
            onError={handleImageError}
          />
        ) : (
          <FallbackIcon>{resource.name[0]}</FallbackIcon>
        )}
      </ResourceIcon>
      <ResourceName>{resource.name}</ResourceName>
      <ResourceDescription>{resource.description}</ResourceDescription>
      <ResourceLink href={getResourceUrl()} target="_blank" rel="noopener noreferrer">
        Visit Site <ExternalLink size={14} />
      </ResourceLink>
      <InfoIcon size={18} />
      {resource.isHovered && (
        <Tooltip>{resource.tooltip}</Tooltip>
      )}
    </ResourceCardContainer>
  );
});

const ResourceCardContainer = styled(animated.div)`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ResourceIcon = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  border-radius: 50%;
  background-color: ${props => props.theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ResourceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FallbackIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.cardBackground};
`;

const ResourceName = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: ${props => props.theme.primary};
`;

const ResourceDescription = styled.p`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin: 0 0 15px 0;
`;

const ResourceLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: ${props => props.theme.accent1};
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.accent2};
  }
`;

const InfoIcon = styled(Info)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.textSecondary};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: 40px;
  right: 10px;
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  padding: 10px;
  font-size: 0.8rem;
  color: ${props => props.theme.text};
  z-index: 10;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const gameResources = {
  lol: [
    {
      name: 'OP.GG',
      dynamicUrl: (gameName, tagLine) => `https://www.op.gg/summoners/na/${encodeURIComponent(gameName)}-${encodeURIComponent(tagLine)}`,
      description: 'Comprehensive player stats and match history',
      icon: 'https://th.bing.com/th/id/OIP.QsbxOU7IMQ6yiIkmv7a0hgHaHa?w=174&h=180&c=7&r=0&o=5&pid=1.7',
      tooltip: 'Get detailed statistics, match history, and build recommendations for League of Legends players.'
    },
    {
      name: 'U.GG',
      dynamicUrl: (gameName, tagLine) => `https://u.gg/lol/profile/na1/${encodeURIComponent(gameName.toLowerCase())}-${encodeURIComponent(tagLine.toLowerCase())}/overview`,
      description: 'Champion builds, tier lists, and pro builds',
      icon: 'https://pbs.twimg.com/profile_images/1146442344662798336/X1Daf_aS_400x400.png',
      tooltip: 'Access champion tier lists, optimal builds, and pro player strategies for climbing the ranked ladder.'
    },
    {
      name: 'Mobalytics',
      dynamicUrl: (gameName, tagLine) => `https://app.mobalytics.gg/lol/profile/na/${encodeURIComponent(gameName.toLowerCase())}-${encodeURIComponent(tagLine.toLowerCase())}/overview`,
      description: 'Personalized performance analysis and improvement tools',
      icon: 'https://th.bing.com/th/id/R.5210fc72f7f84b0d5fc6c433f2bfbca5?rik=HTJeYxqCzqB%2fnA&pid=ImgRaw&r=0',
      tooltip: 'Get personalized game insights, performance analysis, and tailored improvement recommendations.'
    },
    {
      name: 'ProBuilds',
      url: 'https://www.probuilds.net/',
      description: 'Pro player builds and match history',
      icon: 'https://th.bing.com/th/id/OIP.gfNgksbZEF4hmFpZutDrXgAAAA?rs=1&pid=ImgDetMain',
      tooltip: 'Study and emulate the builds and strategies used by professional League of Legends players.'
    },
    {
      name: 'MindGamesWeldon',
      url: 'https://www.youtube.com/user/MindGamesConsulting',
      description: 'Mental game coaching for esports',
      icon: 'https://yt3.googleusercontent.com/ytc/AIdro_n4BAMxDZ1wBJh9_VyrqkwJ114obwwqK1SZucqX2Lw6YvQ=s160-c-k-c0x00ffffff-no-rj',
      tooltip: 'Learn mental game strategies and techniques from a professional esports coach.'
    },
    {
      name: 'DraftLOL',
      url: 'https://draftlol.dawe.gg/',
      description: 'Draft simulation and practice tool',
      icon: 'https://draftlol.dawe.gg/favicon.ico',
      tooltip: 'Practice and improve your champion drafting skills with this simulation tool.'
    },
    {
      name: 'GOL.GG',
      url: 'https://gol.gg/esports/home/',
      description: 'Esports statistics and analytics',
      icon: 'https://gol.gg/favicon.ico',
      tooltip: 'Access comprehensive statistics and analytics for professional League of Legends matches and tournaments.'
    },
    {
      name: 'Rift Kit',
      url: 'https://map.riftkit.net/',
      description: 'Interactive Summoner\'s Rift map',
      icon: 'https://th.bing.com/th/id/OIP.YlL_3Xd3gEbbABo9GY7B6gHaFR?rs=1&pid=ImgDetMain',
      tooltip: 'Explore an interactive map of Summoner\'s Rift to plan strategies and improve map awareness.'
    },
    {
      name: 'Mobalytics Pregame',
      url: 'https://pg.mobalytics.gg/lol/champ-select',
      description: 'Pre-game warmup application',
      icon: 'https://pg.mobalytics.gg/img/loader.gif',
      tooltip: 'Prepare for your games with champion-specific advice and team composition analysis.'
    },
    {
      name: 'Mental Game of Poker',
      url: 'https://www.amazon.com/Mental-Game-Poker-Strategies-Confidence/dp/0615436137',
      description: 'Book on mental game improvement',
      icon: 'https://th.bing.com/th/id/OIP.c9868JeF1ma293FAxDDucwHaD4?w=340&h=180&c=7&r=0&o=5&pid=1.7',
      tooltip: 'While focused on poker, this book offers valuable insights for improving your mental game in any competitive setting.'
    },
  ],
  // Add resources for other games here
};

const ResourceHub = ({ currentGame, userData }) => {
  const [hoveredResource, setHoveredResource] = useState(null);
  const animationProps = useAnimatedMount();
  const resources = useMemo(() => gameResources[currentGame] || [], [currentGame]);

  const cardSpring = useSpring({
    scale: hoveredResource ? 1.05 : 1,
    config: { tension: 300, friction: 10 },
  });

  return (
    <ResourceHubContainer style={animationProps}>
      <ResourceHubTitle>
        <ExternalLink /> Resource Hub
      </ResourceHubTitle>
      <ResourceGrid>
        {resources.map((resource) => (
          <ResourceCard
            key={resource.name}
            resource={{ ...resource, isHovered: hoveredResource === resource.name }}
            onHover={setHoveredResource}
            style={cardSpring}
            userData={userData}
            currentGame={currentGame}
          />
        ))}
      </ResourceGrid>
    </ResourceHubContainer>
  );
};

export default ResourceHub;
